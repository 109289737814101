import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
// import JwtService from "@/core/services/jwt.service";
// import axios from "axios";

// action types
export const UPDATE_TABLE_STRUCTURE = "getDIVISIONTableStructure";
export const UPDATE_TABLE_DATA = "getDIVISIONTableData";
export const EXPORT_TABLE_DATA = "exportDIVISIONTableData";

// mutation types
export const SET_TABLE_STRUCTURE = "setDIVISIONTableStructure";
export const SET_TABLE_DATA = "setDIVISIONTableData";
export const SET_TABLE_FILTER = "setDIVISIONTableFilter";
export const SET_TABLE_PAGE = "setDIVISIONTablePage";
export const SET_TABLE_PERPAGE = "setDIVISIONTablePerPage";
export const SET_TABLE_SORTBY = "setDIVISIONTableSortBy";
export const SET_TABLE_SORTORDER = "setDIVISIONTableSortOrder";
export const SET_EXPORT_LINK = "setDIVISIONExportLink";

const state = {
    export_link: null,
    table_structure: {},
    table_data: {},
    table_state: {
        page: 1,
        per_page: 50,
        sortBy: "id",
        sortOrder: "desc",
        filters: [],
    },
};

const getters = {
    /**
     * for table title, descripton
     * */
    getDIVISIONTableProperties(state) {
        return state.table_structure.properties;
    },
    /**
     * for table permissions
     **/
    getDIVISIONTablePermissions(state) {
        return state.table_structure.permissions;
    },
    getDIVISIONTableState(state) {
        // console.log("table state is ", state.table_state);
        return state.table_state;
    },

    getDIVISIONTableHeaders(state) {
        return state.table_structure.columns;
    },

    getDIVISIONTableFilters(state) {
        return state.table_structure.filters;
    },

    getDIVISIONTableData(state) {
        return state.table_data.data;
    },

    getDIVISIONExportUrl(state) {
        return state.export_link;
    },
};

const actions = {
    [UPDATE_TABLE_STRUCTURE](context) {
        // console.log(payload);
        return new Promise((resolve) => {
            ApiService.post("/organization/divisions")
                .then(({ data }) => {
                    // console.log("suc", data);
                    context.commit(SET_TABLE_STRUCTURE, data);
                    resolve(data);
                })
                .catch((data) => {
                    context.commit(SET_PAGE_LOADING, false);
                    resolve(data);
                });
        });
    },
    [UPDATE_TABLE_DATA](context, payload) {
        context.commit(SET_PAGE_LOADING, true);
        return new Promise((resolve) => {
            // ApiService.post("login", credentials)
            ApiService.post("/organization/divisions/datatable", payload)
                .then(({ data }) => {
                    // console.log("data of datatable ", data);
                    context.commit(SET_TABLE_DATA, data);
                    resolve(data);

                    context.commit(SET_PAGE_LOADING, false);
                    setTimeout(() => {
                        window.scrollTo(0, 0);
                    }, 100);
                })
                .catch((data) => {
                    context.commit(SET_PAGE_LOADING, false);
                    resolve(data);
                });
        });
    },
    [EXPORT_TABLE_DATA](context, payload) {
        context.commit(SET_PAGE_LOADING, true);
        return new Promise((resolve) => {
            ApiService.post("/organization/divisions/export", payload)
                .then(({ data }) => {
                    // console.log("export data is ", data);
                    context.commit(SET_EXPORT_LINK, data.url);
                    resolve(data);
                })
                .catch((data) => {
                    context.commit(SET_PAGE_LOADING, false);
                    resolve(data);
                });
        });
    },
};

const mutations = {
    [SET_TABLE_FILTER](state, filter) {
        state.table_state.filters = filter;
    },
    [SET_TABLE_PAGE](state, page) {
        state.table_state.page = page;
    },
    [SET_TABLE_PERPAGE](state, perPage) {
        state.table_state.per_page = perPage;
    },
    [SET_TABLE_SORTBY](state, sortBy) {
        state.table_state.sortBy = sortBy;
    },
    [SET_TABLE_SORTORDER](state, sortOrder) {
        state.table_state.sortOrder = sortOrder;
    },
    [SET_TABLE_STRUCTURE](state, table_structure) {
        state.table_structure = table_structure.data;
    },
    [SET_TABLE_DATA](state, table_data) {
        state.table_data = table_data;
    },
    [SET_EXPORT_LINK](state, link) {
        state.export_link = link;
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
};
