import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const UPDATE_TABLE_STRUCTURE =
  "getWAREHOUSEALLOCATIONRULESTableStructure";
export const UPDATE_TABLE_DATA = "updateWAREHOUSEALLOCATIONRULESTableData";

// mutation types
export const SET_TABLE_STRUCTURE = "setWAREHOUSEALLOCATIONRULESTableStructure";
export const SET_TABLE_DATA = "setWAREHOUSEALLOCATIONRULESTableData";
export const SET_TABLE_FILTER = "setWAREHOUSEALLOCATIONRULESTableFilter";
export const SET_TABLE_PAGE = "setWAREHOUSEALLOCATIONRULESTablePage";
export const SET_TABLE_PER_PAGE = "setWAREHOUSEALLOCATIONRULESTablePerPage";
export const SET_TABLE_SORT_BY = "setWAREHOUSEALLOCATIONRULESTableSortBy";
export const SET_NOT_VALID = "setWAREHOUSEALLOCATIONRULESNotValid";
export const SET_ACTION_NOT_VALID = "setWAREHOUSEALLOCATIONRULESAcitonNotValid";

const state = {
  table_structure: {},
  table_data: {},
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
  notValid: false,
  actionNotValid: true,
};

const getters = {
  getWAREHOUSEALLOCATIONRULESTableProperties(state) {
    return state.table_structure.properties;
  },
  getWAREHOUSEALLOCATIONRULESTablePermissions(state) {
    return state.table_structure.permissions;
  },
  getWAREHOUSEALLOCATIONRULESTableState(state) {
    return state.table_state;
  },
  getWAREHOUSEALLOCATIONRULESTableHeaders(state) {
    return state.table_structure.columns;
  },

  getWAREHOUSEALLOCATIONRULESTableFilters(state) {
    return state.table_structure.filters;
  },

  getWAREHOUSEALLOCATIONRULESTableData(state) {
    return state.table_data.data;
  },

  getWAREHOUSEALLOCATIONRULESNotValid(state) {
    return state.notValid;
  },
  getWAREHOUSEALLOCATIONRULESAcitonNotValid(state) {
    return state.actionNotValid;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/order_management/warehouse_allocation_rules")
        .then(({ data }) => {
          context.commit(SET_TABLE_STRUCTURE, data);
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post(
        "/order_management/warehouse_allocation_rules/datatable",
        payload
      )
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);
          resolve(data);
          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_NOT_VALID](state, value) {
    state.notValid = value;
  },
  [SET_ACTION_NOT_VALID](state, value) {
    state.actionNotValid = value;
  },
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },

  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PER_PAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORT_BY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
