import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const UPDATE_TABLE_STRUCTURE = "getWMSRECEIVINGTableStructure";
export const UPDATE_TABLE_DATA = "updateWMSRECEIVINGTableData";

// mutation types
export const SET_TABLE_STRUCTURE = "setWMSRECEIVINGTableStructure";
export const SET_TABLE_DATA = "setWMSRECEIVINGTableData";
export const SET_TABLE_FILTER = "setWMSRECEIVINGTableFilter";
export const SET_TABLE_PAGE = "setWMSRECEIVINGTablePage";
export const SET_TABLE_PERPAGE = "setWMSRECEIVINGTablePerPage";
export const SET_TABLE_SORTBY = "setWMSRECEIVINGTableSortBy";
export const SET_TABLE_SORTORDER = "setWMSRECEIVINGTableSortOrder";
export const SET_SKUS = "setWMSRECEIVINGSKUS";
export const SET_SKUS_TABLE_DATA = "setWMSRECEIVINGSKUTableData";
export const SET_SCANNED_SKUS = "setWMSRECEIVINGScannedSKUS";
export const ADD_SCANNED_SKUS = "addWMSRECEIVINGScannedSKUS";
export const EDIT_SCANNED_SKUS = "editWMSRECEIVINGScannedSKUS";
export const REMOVE_SCANNED_SKUS = "removeWMSRECEIVINGScannedSKUS";
export const SET_BLIND_RECEIVE = "setWMSRECEIVINGBlindReceive";
export const SET_RECEIVE_TYPE = "setWMSRECEIVINGReceivingType";
export const SET_SIMPLE_RECEIVE = "setWMSRECEIVINGSimpleReceive";
export const SET_CAN_DECIDE_RECEIVING_TYPE =
  "setWMSRECEIVINGCanDecideReceivingType";
export const SET_IS_BAQ_ASN = "setWMSRECEIVINGIs_BAQ_ASN";
export const SET_SCANNED_CARTONS_FOR_ISS = "setWMSRECEIVINGCartons_For_ISS";
export const SET_HAS_DIMENSIONS_OF_CARTON =
  "setWMSRECEIVINGHasDimensionsOfCarton";
export const TOGGLE_SCANNED_SKUS_GOOD_OR_DAMAGE =
  "toggleScannedSKUSGoodOrDamage";

const state = {
  table_structure: {},
  table_data: {},
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
  skus: {},
  skuTableData: [],
  scannedSKUS: [],
  blindReceive: false,
  receivingType: 1,
  simpleReceive: false,
  is_BAQ_ASN: false,
  cartons_For_ISS: [],
  canDecideReceivingType: false,
  hasDimensionsOfCarton: false, // only for ISS
};

const getters = {
  getWMSRECEIVINGTableProperties(state) {
    return state.table_structure.properties;
  },
  getWMSRECEIVINGTableState(state) {
    return state.table_state;
  },

  getWMSRECEIVINGTableHeaders(state) {
    return state.table_structure.columns;
  },

  getWMSRECEIVINGTableData(state) {
    return state.table_data.data;
  },
  getWMSRECEIVINGSKUS(state) {
    return state.skus;
  },
  getWMSRECEIVINGSkuTableData(state) {
    return state.skuTableData;
  },
  getWMSRECEIVINGScannedSKUS(state) {
    return state.scannedSKUS;
  },
  getWMSRECEIVINGBlindReceive(state) {
    return state.blindReceive;
  },
  getWMSRECEIVINGReceivingType(state) {
    return state.receivingType;
  },
  getWMSRECEIVINGSimpleReceive(state) {
    return state.simpleReceive;
  },
  getWMSRECEIVINGCanDecideReceivingType(state) {
    return state.canDecideReceivingType;
  },
  getWMSRECEIVINGIs_BAQ_ASN(state) {
    return state.is_BAQ_ASN;
  },
  getWMSRECEIVINGCartons_For_ISS(state) {
    return state.cartons_For_ISS;
  },
  getWMSRECEIVINGHasDimensionsOfCarton(state) {
    return state.hasDimensionsOfCarton;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/receiving")
        .then(({ data }) => {
          context.commit(SET_TABLE_STRUCTURE, data);
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/receiving/datatable", payload)
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);
          resolve(data);
          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [TOGGLE_SCANNED_SKUS_GOOD_OR_DAMAGE](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    const { skuName, inputValue } = payload;
    context.state.scannedSKUS.forEach((item) => {
      if (item.id === skuName) {
        item.good_or_damage = inputValue;
      }
    });
    context.commit(SET_PAGE_LOADING, false);
  },
};

const mutations = {
  [SET_HAS_DIMENSIONS_OF_CARTON](state, hasDimensionsOfCarton) {
    state.hasDimensionsOfCarton = hasDimensionsOfCarton;
  },
  [SET_SCANNED_CARTONS_FOR_ISS](state, scannedCartons) {
    if (Array.isArray(scannedCartons)) state.cartons_For_ISS = scannedCartons;
    else state.cartons_For_ISS.push(scannedCartons);
  },
  [SET_IS_BAQ_ASN](state, is_BAQ_ASN) {
    state.is_BAQ_ASN = is_BAQ_ASN;
  },
  [SET_CAN_DECIDE_RECEIVING_TYPE](state, canDecideReceivingType) {
    state.canDecideReceivingType = canDecideReceivingType;
  },
  [SET_SIMPLE_RECEIVE](state, simpleReceive) {
    state.simpleReceive = simpleReceive;
  },
  [SET_RECEIVE_TYPE](state, receivingType) {
    state.receivingType = receivingType;
  },
  [SET_BLIND_RECEIVE](state, blindReceive) {
    state.blindReceive = blindReceive;
  },
  [SET_SCANNED_SKUS](state, scannedSKUS) {
    if (Array.isArray(scannedSKUS)) state.scannedSKUS = scannedSKUS;
    else state.scannedSKUS.push(scannedSKUS);
  },
  [ADD_SCANNED_SKUS](state, scannedSKUS) {
    state.scannedSKUS.push(...scannedSKUS);
  },
  [EDIT_SCANNED_SKUS](state, data) {
    state.scannedSKUS.splice(data.lastElementIndex, 1, data.newItem);
  },
  [REMOVE_SCANNED_SKUS](state, index) {
    state.scannedSKUS.splice(index, 1);
  },
  [SET_SKUS](state, skus) {
    state.skus = skus;
  },
  [SET_SKUS_TABLE_DATA](state, skus) {
    state.skuTableData = skus;
  },
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },
  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_SORTORDER](state, sortOrder) {
    state.table_state.sortOrder = sortOrder;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
