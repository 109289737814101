import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const UPDATE_TABLE_STRUCTURE = "getPENDINGRETURNSTableStructure";
export const UPDATE_TABLE_DATA = "getPENDINGRETURNSTableData";
export const EXPORT_TABLE_DATA = "exportPENDINGRETURNSTableData";

// mutation types
export const SET_TABLE_STRUCTURE = "setPENDINGRETURNSTableStructure";
export const SET_TABLE_DATA = "setPENDINGRETURNSTableData";
export const SET_TABLE_FILTER = "setPENDINGRETURNSTableFilter";
// export const SET_FILTER_CHANGE = "setPENDINGRETURNSFilterChange";
export const SET_TABLE_PAGE = "setPENDINGRETURNSTablePage";
export const SET_TABLE_PERPAGE = "setPENDINGRETURNSTablePerPage";
export const SET_TABLE_SORTBY = "setPENDINGRETURNSTableSortBy";
export const SET_TABLE_SORTORDER = "setPENDINGRETURNSTableSortOrder";
export const SET_EXPORT_LINK = "setPENDINGRETURNSExportLink";
export const SET_CREATE_RETURN_TYPE = "setRETURNCreateReturnType";
export const SET_SERVER_DATA = "setPENDINGRETURNServerData";
export const SET_COLLECTION_ADDRESS_TYPE =
  "setPENDINGRETURNSCollectionAddressType";
export const SET_DESTINATION_ADDRESS_TYPE =
  "setPENDINGRETURNSDestinationAddressType";
export const SET_SELECTED_CUSTOMER = "setPENDINGRETURNSSelectedCustomer";
export const SET_INTERNAL_ORDER_SKUS = "setRETUNRSInternalOrderSkus";
export const SET_ACTION_TYPE = "setPENDINGRETURNSActionType";
export const SET_EDIT_DATA = "setPENDINGRETURNSEditData";
export const SET_EDITED_SKU = "setPENDINGRETURNSEditedSku";
export const SET_CUSTOM_COLLECTION_ADDRESS =
  "setPENDINGRETURNSCustomCollectionAddress";
export const SET_CUSTOM_DESTINATION_ADDRESS =
  "setPENDINGRETURNSCustomDestinationAddress";

const state = {
  queryObject: {},
  export_link: null,
  table_structure: {},
  table_data: {},
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
  create_return_type: "external",
  serverData: {},
  collection_address_type: null,
  destination_address_type: 2,
  selected_customer: null,
  internal_order_skus: null,
  action_type: "create",
  edit_data: null,
  edited_sku: null,
  custom_collection_address: {},
  custom_destination_address: {},
};

const getters = {
  getPENDINGRETURNSExtraData(state) {
    return state.table_structure.reason_data;
  },
  /**
   * for table title, description
   * */
  getPENDINGRETURNSTableProperties(state) {
    return state.table_structure.properties;
  },
  /**
   * for table permissions
   **/
  getPENDINGRETURNSTablePermissions(state) {
    return state.table_structure.permissions;
  },
  getPENDINGRETURNSPrintTypes(state) {
    return state.table_structure.print_labels;
  },
  getPENDINGRETURNSTableState(state) {
    return state.table_state;
  },
  getPENDINGRETURNSTableHeaders(state) {
    return state.table_structure.columns;
  },

  getPENDINGRETURNSTableFilters(state) {
    return state.table_structure.filters;
  },

  getPENDINGRETURNSTableData(state) {
    return state.table_data.data;
  },

  getPENDINGRETURNSExportUrl(state) {
    return state.export_link;
  },
  getPENDINGRETURNSCreateReturnType(state) {
    return state.create_return_type;
  },
  getPENDINGRETURNSServerData(state) {
    return state.serverData;
  },
  getPENDINGRETURNSSelectedCollectionAddressType(state) {
    return state.collection_address_type;
  },
  getPENDINGRETURNSSelectedDestinationAddressType(state) {
    return state.destination_address_type;
  },
  getPENDINGRETURNSSelectedCustomer(state) {
    return state.selected_customer;
  },
  getPENDINGRETURNSInternalOrdersSkus(state) {
    return state.internal_order_skus;
  },
  getPENDINGRETURNSActionType(state) {
    return state.action_type;
  },
  getPENDINGRETURNSEditData(state) {
    return state.edit_data;
  },
  getPENDINGRETURNSCarriers(state) {
    return state.table_structure.carriers;
  },
  getPENDINGRETURNSDestinationAddressType(state) {
    return state.table_structure.destination_address_types;
  },
  getPENDINGRETURNSDestinationCustomerAddresses(state) {
    return state.table_structure.customer_addresses;
  },
  getPENDINGRETURNSDestinationCarrierAddersses(state) {
    return state.table_structure.carrier_addresses;
  },
  getPENDINGRETURNSDestinationWarehouses(state) {
    return state.table_structure.warehouses;
  },
  getPENDINGRETURNSCustomCollectionAddress(state) {
    return state.custom_collection_address;
  },
  getPENDINGRETURNSCustomDestinationAddress(state) {
    return state.custom_destination_address;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/order_management/pending_audit_returns")
        .then(({ data }) => {
          context.commit(SET_TABLE_STRUCTURE, data);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post(
        "/order_management/pending_audit_returns/datatable",
        payload
      )
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);
          resolve(data);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [EXPORT_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/order_management/pending_audit_returns/export", payload)
        .then(({ data }) => {
          context.commit(SET_EXPORT_LINK, data.url);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },

  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_SORTORDER](state, sortOrder) {
    state.table_state.sortOrder = sortOrder;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
  [SET_EXPORT_LINK](state, link) {
    state.export_link = link;
  },
  [SET_CREATE_RETURN_TYPE](state, type) {
    state.create_return_type = type;
  },
  [SET_SERVER_DATA](state, data) {
    state.serverData = data;
  },
  [SET_COLLECTION_ADDRESS_TYPE](state, type) {
    state.collection_address_type = type;
  },
  [SET_DESTINATION_ADDRESS_TYPE](state, type) {
    state.destination_address_type = type;
  },
  [SET_SELECTED_CUSTOMER](state, customer) {
    state.selected_customer = customer;
  },
  [SET_INTERNAL_ORDER_SKUS](state, details) {
    state.internal_order_skus = details;
  },
  [SET_ACTION_TYPE](state, type) {
    state.action_type = type;
  },
  [SET_EDIT_DATA](state, data) {
    state.edit_data = data;
  },
  [SET_EDITED_SKU](state, data) {
    state.edited_sku = data;
  },
  [SET_CUSTOM_COLLECTION_ADDRESS](state, data) {
    state.custom_collection_address = data;
  },
  [SET_CUSTOM_DESTINATION_ADDRESS](state, data) {
    state.custom_destination_address = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
